import { Component, MouseEvent, ReactNode } from 'react';
import { Button } from 'antd-mobile';

export default class TestComponent extends Component {

    click(event: MouseEvent): void {
        alert("click");
    }

    render(): ReactNode {
        return <Button type="primary" onClick={this.click}>TestComponent</Button>;
    }
}
