import React, { Component, Fragment, ReactNode } from 'react';
import Screen from '../../components/toolkit/Screen';
import View from '../../components/toolkit/mobile/BasicComponents/View';
import HoofdkraanScreen from '../HoofdkraanScreen/HoofdkraanScreen';
import FooterMenuBar from '../../components/FooterMenuBar/FooterMenuBar';
import { Button, Card } from 'antd-mobile';
import { makeObservable, observable, computed, action, reaction, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { getConnection, getConnectionManager, getRepository } from 'typeorm';
import { getDataSource } from '../../data/DataSource/DataSource';
import styled from 'styled-components';
import Settings from '../../settings/Settings';

// Temporary types until we implement DataObjects
type IdFieldType = string | undefined;
class DataObject extends Object {}

export class DataObjectCollection<DataObjectType> implements Displayable<ReactNode[]> {

    public map = new Map<IdFieldType, DataObjectType>(); 

    display(display: Display<React.ReactNode>): React.ReactNode[] {
        let reactNodes: Array<ReactNode> = [];
        this.map.forEach((value: DataObjectType, key: IdFieldType):void => {
                reactNodes.push(display.visit(value));
            });
        return reactNodes;
    }

    forEach(func: (value: DataObjectType, key: IdFieldType, map: Map<IdFieldType, DataObjectType>) => void): void {
        this.map.forEach(func);
    }

    set(key: IdFieldType, value: DataObjectType) {
        this.map.set(key, value); 
    }

    get(key: IdFieldType): DataObjectType {
        return this.map.get(key)!;
    } 

    get size(): number {
        return this.map.size;
    }
}
export class Project extends DataObject implements Displayable<ReactNode>, Convertable<string> {
    id: string;
    title: string;
    description:string;
    minBudget: string;
    maxBudget: string;
    publishDate: string;

    display(display: Display<React.ReactNode>): React.ReactNode {
        return display.visit(this);
    }
    convert(converter: Converter<string>): string {
        throw new Error('Method not implemented.');
    }
}

class User extends DataObject implements Displayable<ReactNode> {
    display(display: Display<React.ReactNode>): React.ReactNode {
        throw new Error('Method not implemented.');
    }
}

class DataCollection<DataObjectType> extends Map<IdFieldType, DataObjectType> {
}

interface Visitee<ReturnType> {
    //accept(visitor: Visitor<ReturnType>): ReturnType;
}

interface Displayable<ReturnType> extends Visitee<ReturnType> {
    display(display: Display<ReturnType>): ReturnType;
}

interface Convertable<ReturnType> extends Visitee<ReturnType> {
    convert(converter: Converter<ReturnType>): ReturnType;
}

interface Visitor<ReturnType> {
    visit(visitee: Visitee<ReturnType>): ReturnType;
}

interface Display<ReturnType> extends Visitor<ReturnType> {
    //display(visitor: Visitor<ReturnType>): ReturnType;
}

interface Converter<ReturnType> extends Visitor<ReturnType> {
}


class ObjectCollectionDisplay implements Display<ReactNode> {
    visit(visitee: Visitee<React.ReactNode>): React.ReactNode {
        throw new Error('Method not implemented.');
    }
}

class DefaultDisplay implements Display<ReactNode> {

    visit(visitee: Visitee<React.ReactNode>): ReactNode {
        const functionName: keyof DefaultDisplay = "display" + visitee.constructor.name as keyof DefaultDisplay;
        return (this[functionName](visitee) as ReactNode);
    }

}

class ProjectListDisplay extends DefaultDisplay {

    displayProject(visitee: Visitee<ReactNode>): ReactNode {
        let project = visitee as Project;
        return (
            <ProjectListCard
                project={ project }
            />
        );
    }
}

type ProjectListCardProps = {
    project: Project
}


const TitleView = styled(View)`
    font-size: 20px;
    color: ${Settings.UI.Colors.defaultGreen.htmlColor};
`;

const DescriptionView = styled(View)`
    line-height: 1rem;
    color: ${Settings.UI.Colors.defaultTextColor.htmlColor};
`;

const PropertiesView = styled(View)`
    color: ${Settings.UI.Colors.defaultTextColor.htmlColor};
`;

@observer
class ProjectListCard extends Component<ProjectListCardProps> {
    @observable
    project: Project;

    constructor(props: ProjectListCardProps) {
        super(props);
        this.project = props.project;
        makeObservable(this, {
            project: observable,
        });
    }

    render(): ReactNode {
        let project = this.props.project;
        console.log(project.publishDate);
        let publishDate = new Date();
        return (
            <Card full>
                <Card.Header
                    title={ <TitleView>{ project.title }</TitleView> }
                />
                <Card.Body>
                    <PropertiesView>Budget: { project.minBudget } - { project.maxBudget } * Geplaatst: { publishDate.toDateString() }</PropertiesView>
                    <DescriptionView>{project.description}</DescriptionView>
                </Card.Body>
                {/*<Card.Footer content="footer content" extra={ <div>extra footer content</div> } />*/}
            </Card>
        );
    }
}

type DataDisplayComponentProps = {
    dataCollection: DataObjectCollection<Project>, 
    display: Display<ReactNode>
}

@observer
class DataDisplayComponent extends Component<DataDisplayComponentProps> {
    //@observable
    //dataCollection: DataObjectCollection<Project>;

    //@observable
    //display: Display<ReactNode>;

/*
    @computed
    get dataCollection(): Map<IdFieldType, DataObject> {
        return this.rawDataCollection as Map<IdFieldType, DataObject>;
    }

    set dataCollection(dataCollection: Map<IdFieldType, DataObject>) {
        console.log("DataDisplayComponent set dataCollection");
        this.rawDataCollection = dataCollection as DataObjectCollection<Project>;
    }
*/

    constructor(props: DataDisplayComponentProps) {
        super(props);
        //this.dataCollection = props.dataCollection;
        //this.display = props.display;
        //makeObservable(this, {
            //display: observable,
            //dataCollection: observable
        //});
    }
}


@observer
class ProjectList extends DataDisplayComponent {

    componentDidMount() {
        console.log("ProjectList mounted");
    }

    render(): ReactNode {
        console.log("ProjectList render()");
        console.log(this.props.dataCollection);
        if(this.props.dataCollection !== undefined) {
            let projectCardNodes: ReactNode[] = [];
            this.props.dataCollection.forEach((value: Project) => {
                projectCardNodes.push(
                <ProjectListCard
                    project={ value }
                />
                );
            });
            let output: ReactNode = (
            <Fragment>
                <View className="ProjectList1">
                    { projectCardNodes }
                </View>
                <View className="ProjectList2"> {this.props.dataCollection.size} opdrachten </View>
            </Fragment>
            );
            return output;
        } else {
            return <View>Data wordt geladen ...</View>
        }
    }

}

const ProjectListScreenView = styled(View)`
`;

type ProjectListScreenProps = {}

class XDataObjectCollection<T> extends Set<T> {
}

@observer
export default class ProjectListScreen extends Component<ProjectListScreenProps> {
    @observable
    dataCollection: DataObjectCollection<Project>;

    constructor(props: ProjectListScreenProps) {
        super(props);
        this.loadDataObjectCollection();
        makeObservable(this, {
            dataCollection: observable,
            loadDataObjectCollection: action,
            setData: action
        });
        this.loadData = this.loadData.bind(this);
        this.setData = this.setData.bind(this);
    }

/*
    get dataCollection(): DataObjectCollection<Project> {
        return this.dataCollection;
    }

    set dataCollection(dataCollection: DataObjectCollection<Project>) {
        console.log("ProjectListScreen set dataCollection");
        this.dataCollection = dataCollection;
    }
*/

/*
    @computed
    get dataCollection(): Map<IdFieldType, DataObject> {
        return this.dataCollection as Map<IdFieldType, DataObject>;
    }

    set dataCollection(dataCollection: Map<IdFieldType, DataObject>) {
        console.log("ProjectListScreen set dataCollection");
        this.rawDataCollection = dataCollection as DataObjectCollection<Project>;
    }
*/

    @action
    loadDataObjectCollection(): void {
        //const project = getRepository(Project).findOne(2708);
        //let dataObjectCollection = new DataObjectCollection<Project>();
        //dataObjectCollection.set("0", new Project());
        //dataObjectCollection.set("1", new Project());
        console.log('hier - voor');
        let dataObjectCollection = getDataSource().getLatestProjects2().then((result) => { 
            console.log("callback");
            this.setData(result);
        });
        console.log('hier - na');
        console.log(dataObjectCollection);
        //setTimeout(() => { console.log("timeout voorbij"); this.setData(dataObjectCollection); }, 5000);
        //return dataObjectCollection;
    }

    @action
    setData(dataObjectCollection: DataObjectCollection<Project>) {
        console.log("setData action");
        this.dataCollection = dataObjectCollection;

    }

    loadData(): void {
        this.loadDataObjectCollection();
    }

    componentDidMount() {
        console.log("ProjectListScreen mounted");
    }

    render(): ReactNode {
        console.log("ProjectListScreen: render()");
        console.log(this.dataCollection);
        return (
            <View className="ProjectListScreen">
                <ProjectList 
                    dataCollection={this.dataCollection}
                    display={new ProjectListDisplay()}
                />
            </View>
        );
    }
}
