import React, { Component, MouseEvent, ReactNode } from 'react';

import { Button } from 'antd';

//import UserDashboardOptions from 'UserDashboardOptions';

export default class UserDashboard extends React.Component {

    click(event: MouseEvent): void {
        alert("click");
    }

    render(): JSX.Element {
        return <Button type="primary" onClick={this.click}> UserDashboard TSX component</Button>;
    }
}