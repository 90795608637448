import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './application/components/App';
import reportWebVitals from './reportWebVitals';
//import UserDashboard from './application/components/UserDashboard/UserDashboard';
//import 'antd/dist/antd.css';
import './application/css/antd-mobile-prefixed.css';
import 'reflect-metadata';

function renderComponents() {
    let reactComponents = Array.from(document.getElementsByClassName("reactComponent"));
    console.log(reactComponents);
    reactComponents.forEach((componentDescriptionNode) => {
        let componentNode = renderComponent(componentDescriptionNode);
    ReactDOM.render(
            <React.StrictMode>
              <App>
                {componentNode}
              </App>
            </React.StrictMode>,
            componentDescriptionNode
        );

    /*
        import('./application/components/' + ComponentClass + '/' + ComponentClass)
          .then((module) => {
            //var ComponentInstance = components[componentClass];
            let ReactComponentInstance = <ComponentClass />;
            //componentNode.appendChild(reactComponentInstance);
            console.log(ReactComponentInstance);
        console.log(ComponentClass);
            ReactDOM.render(
                React.createElement(ComponentClass, {}),
                componentNode
            );
        });
        */
            
    });
}

function renderComponent(componentNode){
    let ComponentClass = componentNode.getAttribute("id");
    console.log(ComponentClass);
    //ComponentClass = "UserDashboard";
    //var ReactComponent = require('./application/components/' + ComponentClass + '/' + ComponentClass);
    let ReactModule = require('./application/' + ComponentClass);
    console.log(ReactModule);
    ComponentClass = ReactModule.default;
    console.log(ComponentClass);
    return <ComponentClass />;
    /*
    ReactDOM.render(
            <ComponentClass />,
            componentNode
        );
    */
}

renderComponents();

{/*
export function testFunction(param) {
    console.log(param);
}

const gotoPage = (page) => {
    import('./application/pages/' + page);
}

const loadComponent = (component) => {
}
*/}
//console.log(testVariable);
/*
ReactDOM.render(
    <UserDashboard />
    ,
    document.getElementById('reactRoot')
);
*/
{/*
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('reactRoot')
);
 * 
 */}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
