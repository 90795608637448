import React, { Component, ReactNode } from 'react';
import Settings from "../../settings/Settings";
import {createConnection, Connection} from "typeorm";
import View from '../toolkit/mobile/BasicComponents/View';
import './App.css';
import { getConnection, getConnectionManager, getRepository } from 'typeorm';

class App extends Component {
    
    constructor(props) {
        super(props);
        console.log(Settings.database);
        //this.initDatabaseConnection();
    }

    initDatabaseConnection() {
        console.log(Settings.database);
        console.log('hier');
        const connection = createConnection(
           Settings.database 
        );
        //console.log("Has connection: " + getConnectionManager().has("expertmarkt"));
        //console.log(getConnection().entityMetadatas);
    }

    render() {
        return (
            <View className="App">
                {this.props.children}
            </View>
        );
    }
}

export default App;
