import { Component, ReactNode } from "react";

export default class Text extends Component {
    render(): ReactNode {
        return (
            <span {...this.props}>
                {this.props.children}
            </span>
        );
    }
}
