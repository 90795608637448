import React, { Component, Fragment, ReactElement, ReactNode } from "react";
import styled, { StyledComponent } from 'styled-components';
import { Icon, TabBar, View } from "antd-mobile";
import Settings from "../../settings/Settings";
import HoofdkraanScreen from "../../screens/HoofdkraanScreen";
import FooterMenuBar from "../../components/FooterMenuBar/FooterMenuBar";

export default class HomeScreen extends Component {

    render(): ReactNode {
        return  (
            <Fragment>
                <FooterMenuBar />
            </Fragment>
        );
    }
}

{/*
type TextPresentationProps = {
    fontSize: string,
    fontName: string,
}

type AllPresentationProps = {
}
    

type DisplayComponentProps = {
    presentation: AllPresentationProps
}

class DisplayComponent<P> extends Component<DisplayComponentProps, any> {
}

class Text extends DisplayComponent<TextPresentationProps> {
    render(): ReactNode {
        return 
            <View ...props />;
    }
}

*/}
