import { DataObjectCollection, Project } from "../../screens/ProjectListScreen/ProjectListScreen";

type ResultObject = {
    result: Array<Map<string, string>>;
}

class DataSource {

    static dataSource: DataSource;

    async getLatestProjects2(): Promise<DataObjectCollection<Project>> {
      const testUserId = 6;
        let baseUrl = window.location.origin; 
        //console.log(baseUrl);
        if (baseUrl === "http://localhost:3000") baseUrl = "http://hoofdkraan-dev.nl";
        //if (baseUrl === "https://test.hoofdkraan.nl") baseUrl = "http://test.hoofdkraan.nl";
        //console.log(baseUrl);
        let response = await fetch(baseUrl + "/api/ModelApi/getLatestProjects");

      if (!response.ok) throw new Error("Data couldn't be fetched.");

        //let self = this;
        let rawData = await response.json();
        let formattedData = await this.processApiResult(rawData);
        console.log("FormattedData:");
        console.log(formattedData);
        return formattedData;
      }
        
    getLatestProjects(): DataObjectCollection<Project> {
      const testUserId = 6;
      fetch("http://hoofdkraan-dev.nl/api/modelApi/getLatestProjects")
      .then(res => res.json())
      .then(
        (result: ResultObject) => {
            /*
          this.setState({
            isLoaded: true,
            items: result.items
          });
          */
          return this.processApiResult(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
            /*
          this.setState({
            isLoaded: true,
            error
          });
          */
          return new DataObjectCollection<Project>();
        }
      );

    return new DataObjectCollection<Project>();
    }

    processApiResult(result: ResultObject): DataObjectCollection<Project> {
        let dataObjectCollection = new DataObjectCollection<Project>();
        console.log(result);

        result.result.forEach((v: Map<string, string>) => {
            //let values = value.values();
            let value = new Map<string, string>(Object.entries(v));
            console.log(value);
            let project = new Project();
            project.id = value.get("id")!;
            project.title = value.get("title")!;
            project.description = value.get("description")!;
            project.minBudget = value.get("minBudget")!;
            project.maxBudget = value.get("maxBudget")!;
            dataObjectCollection.set(value.get("id"), project);
        });
        return dataObjectCollection;

    }

    static get(): DataSource {
        if (DataSource.dataSource == null) 
            DataSource.dataSource = new DataSource();
        return DataSource.dataSource;

    }
}

export function getDataSource(): DataSource {
    return DataSource.get();
}