import { Color } from "../classes/Color"

const Settings = {
    UI: {
        Colors: {
            defaultOrange: new Color("#FF5E00"),
            defaultGreen: new Color("#3c99ac"),
            defaultLightBlue: new Color("#f1f8f8"),
            defaultDarkGray: new Color("#666"),
            defaultTextColor: new Color("#474747"),
        }
    },
    database: {
        "type": "mysql",
        "host": "localhost",
        "port": 3306,
        "username": "root",
        "password": "root",
        "database": "expertmarkt",
        "synchronize": false,
        "logging": false,
        "entities": [
           "src/application/data/entities/**/*.tsx"
        ],
        //"migrations": [
           //"src/data/migration/**/*.ts"
        //],
        //"subscribers": [
           //"src/data/subscriber/**/*.ts"
        //]
    }
}

export default Settings;