import { ReactNode } from "react";
import Container from "../mobile/Container";
import View from "../mobile/BasicComponents/View";

export default class StickyFooter extends Container {
    render(): ReactNode {
        return
            <View />
    }
}
