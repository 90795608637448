import React from 'react';
import ReactDOM from 'react-dom';
import App from '../App/App';

export default class ReactPHPComponent extends React.Component {

    constructor(props) {
        super(props);
    }

    render () {
            return (<React.StrictMode>
              <App>
                  Hallo daar.
                  {this.props.children}
              </App>
            </React.StrictMode>);
    }

    wrapComponent(component) {
        return ReactDOM.render(
            <React.StrictMode>
              <App>
                  {component}
              </App>
            </React.StrictMode>,
            document.getElementById('reactRoot')
        );
    }
}