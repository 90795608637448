import React, { ReactNode } from "react";
import FooterMenuBar from "../../components/FooterMenuBar";


export default class HoofdkraanScreen extends Screen {
    render(): ReactNode {
        return 
            <FooterMenuBar />
    }
}
