type RGBColor = { red: number, green: number, blue: number };

export class Color {
    
    color: string;
    
    constructor(color: string) {
        this.color = color;
    }

    get htmlColor(): string {
        return this.color;
    }

    set htmlColor(color: string) {
        this.color = color;
    }
}
