import { Icon, TabBar } from "antd-mobile";
import React, { Component, Fragment, ReactNode, Ref } from "react";
import styled from "styled-components";
import { UserOutlined, UnorderedListOutlined, MessageOutlined } from "@ant-design/icons";
import Settings from "../../settings/Settings";
import { Item } from "antd-mobile/lib/tab-bar";
import { makeObservable, observable, computed, action } from "mobx"
import ProjectListScreen from "../../screens/ProjectListScreen/ProjectListScreen";
import TestComponent from "../SandBox/TestComponent";

const FooterMenuBarIcon = styled(Icon)`
            widht: 30px;
            height: 30px;
`;

type FooterMenuBarProps = {
}

type FooterMenuBarState = {
}

export default class FooterMenuBar extends Component<FooterMenuBarProps, FooterMenuBarState> {
    //@observable
    selectedTab = <ProjectListScreen />;
    

    private tabRef = React.createRef<Item>();

    constructor(props: FooterMenuBarProps) {
       super(props);
    }
       
    
    handleOnPressItem(): void {
        //this.selectedMenuItem = this.tabRef.current;
    }

    render(): ReactNode {
        return (
            <Fragment>
                <TabBar
                    barTintColor={ Settings.UI.Colors.defaultLightBlue.htmlColor }
                    tintColor={ Settings.UI.Colors.defaultOrange.htmlColor }
                    unselectedTintColor={ Settings.UI.Colors.defaultDarkGray.htmlColor }
                >

                    <TabBar.Item
                        ref={this.tabRef}
                        icon={ <UnorderedListOutlined /> }
                        title="Opdrachten"
                        onPress={ this.handleOnPressItem }
                    >
                        {this.selectedTab}
                    </TabBar.Item>
                    
                    <TabBar.Item
                        ref={this.tabRef}
                        icon={ <MessageOutlined /> }
                        title="Berichten"
                        onPress={ this.handleOnPressItem }
                    />

                    <TabBar.Item
                        ref={this.tabRef}
                        icon={ <UserOutlined /> }
                        title="Profiel"
                        onPress={ this.handleOnPressItem }
                    />
                </TabBar>
            </Fragment>
        );
    }
}
