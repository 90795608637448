import React from 'react';

class UploadFileComponent extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div class="fileUploadComponent">
                <label for={this.props.name}>{this.props.labelContent}</label>
                <input name={this.props.name} type="file" class="form-control-file" />
                <div class="row mt-3">
                    <div class="col-12">
                        <div class="uploadFilePreview coverImagePreview">
                            <div class="processIndicator display-none">
                                <img src="img/loader2.gif" alt="Loading" />
                            </div>
                            <img class="previewImage" src="" alt={this.props.previewAlt} />
                        </div>
                    </div>
                </div>
                <div class="fileUploadComponentError"></div>;
            </div>
        );
    }
}


/*
ReactDOM.render(
    <UploadFileComponent />,
    document.getElementByClass('UploadFileComponent')
);
 * 
 */