import { observer } from "mobx-react";
import { Component, ReactNode } from "react";

type ViewProps = {
    //attributes: {
        //className: string
    //}
    className?: string
}

@observer
export default class View extends Component<ViewProps> {
    render(): ReactNode {
        return (
            <div {...this.props}>
                {this.props.children}
            </div>
        );
    }
}
