import { Entity, PrimaryGeneratedColumn, Column } from "typeorm";

@Entity({name: "projects"})
export class Project {

    @PrimaryGeneratedColumn()
    id: number;

    @Column()
    clientId: number;

    @Column()
    status: number;

    @Column()
    title: string;

    @Column()
    description: string;

}
